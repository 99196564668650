import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import Select from 'react-select';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';

const RepuestoEditModal = ({ item, selectsData, closeModal, onModalEditSubmit, columnsEnabled = [] ,action, showMarca, onSelectCategoria}) => {
    const intl = useIntl();
    const { categorias, subcategorias, unidades, tipos, marcas } = selectsData;
    const obligatorios = ConfigBusiness.get('panol.items.obligatorios')
        .split(',')
        .map((col) => col.trim());
    const [form, setForm] = useState({ ...item });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        onSelectCategoria(form.categoria)
    }, [form.categoria])

    let datoRequerido = intl.formatMessage({ id: 'validation.required', defaultMessage: 'Dato requerido' });

    const sendIfValid = (form) => {
        const reqMissing = {};
        Object.keys(form).forEach((campo) => {
            if (obligatorios.includes(campo)) {
                if (!form[campo]) {
                    reqMissing[campo] = datoRequerido;
                }
            }
        });

        setErrors(reqMissing);

        if (Object.keys(reqMissing).length === 0) {

            let submitForm = { ...form }
            submitForm.categoria = form.categoria ? (form.categoria.value ? form.categoria.value : form.categoria) : form.categoria;
            submitForm.subcategoria = form.subcategoria ? (form.subcategoria.value ? form.subcategoria.value : form.subcategoria) : form.subcategoria;
            submitForm.unidad = form.unidad ? (form.unidad.value ? form.unidad.value : form.unidad) : form.unidad;
            submitForm.tipo = form.tipo ? (form.tipo.value ? form.tipo.value : form.tipo) : form.tipo;
            submitForm.marca = form.marca ? (form.marca.value ? form.marca.value : form.marca) : form.marca;

            onModalEditSubmit(submitForm);
        }
    };

    useEffect(() => {
        setForm({ ...item });
    }, [item]);


    const onClose = () => {
        setErrors({});
        setForm({});
        closeModal();
    }

    return (
        <div
            className="modal fade"
            id="editItemsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editItemsModalLabel"
            aria-hidden="true"
            data-backdrop="static" data-keyboard="false"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        {action === 'edit' ?
                            <h5 className="modal-title" id="editItemsModalLabel">
                                <FormattedMessage
                                    id="itemAbm.render.modal_edit.header_editar_item"
                                    defaultMessage="Editar Item"
                                />
                            </h5>
                        :  null}
                        {action === 'add' ?
                            <h5 className="modal-title" id="editItemsModalLabel">
                                <FormattedMessage
                                    id="Agregar_Item"
                                    defaultMessage="Agregar Item"
                                />
                            </h5>
                        : null}
                        <button type="button" className="close" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {columnsEnabled.descripcion && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="editNombreItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_descripcion"
                                                defaultMessage="Descripcion del Item:"
                                            />
                                            {obligatorios.includes('descripcion') ? '*' : ''}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="descripcion"
                                            id="editNombreItem"
                                            value={form.descripcion}
                                            onChange={(e) => setForm({ ...form, descripcion: e.target.value })}
                                        />
                                        {errors.descripcion && <small className="text-danger">{errors.descripcion}</small>}
                                    </div>
                                </div>
                            )}
                                {columnsEnabled.categoria && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editSubCatItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_categoria"
                                                defaultMessage="Categoria:"
                                            />
                                            {obligatorios.includes('categoria') ? '*' : ''}
                                        </label>
                                        <Select
                                            id="categoria"
                                            name="categoria"
                                            options={categorias}
                                            value={form.categoria}
                                            onChange={(e) => {
                                                setForm({ ...form, categoria: e });
                                                if (e) {
                                                    onSelectCategoria(e.value)
                                                }
                                            }}
                                        />
                                        {errors.categoria && <small className="text-danger">{errors.categoria}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.subcategoria && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editSubCatItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_subCategoria"
                                                defaultMessage="SubCategoria:"
                                            />
                                            {obligatorios.includes('subcategoria') ? '*' : ''}
                                        </label>
                                        <Select
                                            id="subcategoria"
                                            name="subcategoria"
                                            options={subcategorias}
                                            value={form.subcategoria}
                                            onChange={(e) => setForm({ ...form, subcategoria: e })}
                                        />
                                        {errors.subcategoria && <small className="text-danger">{errors.subcategoria}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.costo && (
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="editCostoItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_costo"
                                                defaultMessage="Costo:"
                                            />
                                            {obligatorios.includes('costo') ? '*' : ''}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="costo"
                                            id="editCostoItem"
                                            placeholder={intl.formatMessage({
                                                id: 'itemAbm.render.modal_edit.placeholder_costo',
                                                defaultMessage: 'Ej: 1250',
                                            })}
                                            value={form.costo}
                                            onChange={(e) => setForm({ ...form, costo: e.target.value })}
                                        />
                                        {errors.costo && <small className="text-danger">{errors.costo}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.unidad && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editMedidaItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_unidad_de_medida"
                                                defaultMessage="Unidad de medida:"
                                            />
                                            {obligatorios.includes('unidad') ? '*' : ''}
                                        </label>
                                        <Select
                                            id="unidad"
                                            name="unidad"
                                            options={unidades}
                                            value={form.unidad}
                                            onChange={(e) => setForm({ ...form, unidad: e })}
                                        />
                                        {errors.unidad && <small className="text-danger">{errors.unidad}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.tipo && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editTipoItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_tipo"
                                                defaultMessage="Tipo:"
                                            />
                                            {obligatorios.includes('tipo') ? '*' : ''}
                                        </label>
                                        <Select
                                            id="tipo"
                                            name="tipo"
                                            valueKey="value"
                                            labelKey="label"
                                            options={tipos}
                                            value={form.tipo}
                                            onChange={(e) => setForm({ ...form, tipo: e })}
                                        />
                                        {errors.tipo && <small className="text-danger">{errors.tipo}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.numero_parte && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editNroParteItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_nro_de_parte"
                                                defaultMessage="Nro de parte:"
                                            />
                                            {obligatorios.includes('numero_parte') ? '*' : ''}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="numeroDeParte"
                                            id="editNroParteItem"
                                            placeholder={intl.formatMessage({
                                                id: 'itemAbm.render.modal_edit.placeholder_nro_de_parte',
                                                defaultMessage: 'Ej: 000153654',
                                            })}
                                            value={form.numero_parte}
                                            onChange={(e) => setForm({ ...form, numero_parte: e.target.value })}
                                        />
                                        {errors.numero_parte && <small className="text-danger">{errors.numero_parte}</small>}
                                    </div>
                                </div>
                            )}
                            {columnsEnabled.referencia && (
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="referenciaItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_referencia"
                                                defaultMessage="Referencia:"
                                            />
                                            {obligatorios.includes('referencia') ? '*' : ''}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name="referencia"
                                            id="editReferenciaItem"
                                            placeholder={intl.formatMessage({
                                                id: 'itemAbm.render.modal_edit.placeholder_referencia',
                                                defaultMessage: 'Ej: alguna referencia de algo',
                                            })}
                                            value={form.referencia}
                                            onChange={(e) => setForm({ ...form, referencia: e.target.value })}
                                        />
                                        {errors.referencia && <small className="text-danger">{errors.referencia}</small>}
                                    </div>
                                </div>
                            )}

                            {columnsEnabled.marca && showMarca ? (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editMarcaItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_marca"
                                                defaultMessage="Marca:"
                                            />
                                            {obligatorios.includes('marca') ? '*' : ''}
                                        </label>
                                        <Select
                                            id="marca"
                                            name="marca"
                                            options={marcas}
                                            value={form.marca}
                                            onChange={(e) => setForm({ ...form, marca: e })}
                                        />
                                        {errors.marca && <small className="text-danger">{errors.marca}</small>}
                                    </div>
                                </div>
                            ) : ''}
                            {columnsEnabled.external_code && (
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="editExternalCodeItem" className="col-form-label">
                                            <FormattedMessage
                                                id="itemAbm.render.modal_edit.lavel_external_code"
                                                defaultMessage="External Code:"
                                            />
                                            {obligatorios.includes('external_code') ? '*' : ''}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="externalCode"
                                            id="editExternalCodeItem"
                                            placeholder={intl.formatMessage({
                                                id: 'itemAbm.render.modal_edit.placeholder_external_code',
                                                defaultMessage: 'External Code',
                                            })}
                                            value={form.external_code}
                                            onChange={(e) => setForm({ ...form, external_code: e.target.value })}
                                        />
                                        {errors.external_code && <small className="text-danger">{errors.external_code}</small>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            <FormattedMessage
                                id="itemAbm.render.modal_edit.finish_button.cancelar"
                                defaultMessage="Cancelar"
                            />
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => sendIfValid(form)}
                        >
                            <FormattedMessage
                                id="itemAbm.render.modal_edit.finish_button.guardar_datos"
                                defaultMessage="Guardar datos"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RepuestoEditModal;
